import menuStateActionTypes from "../actions/menuStateActionTypes";

function menuStateReducer(state='', action) {
  switch (action.type) {
    case menuStateActionTypes.OPENED_MENU:
        return action.state;
    case menuStateActionTypes.CLOSED_MENU:
      return action.state;
    default:
      return state;
  }
}

export default menuStateReducer;
