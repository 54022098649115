import ratedActionTypes from "../actions/ratedActionTypes";
import api from '../stores/data.json';

export function loadRated() {
  //español
  const mapMovies = [];
  for (let i=0; i < api.movies.length; i++){
    if (api.movies[i].vote_average >= 7.5){
        mapMovies.push(api.movies[i]);
      }
  }
    return {
      type: ratedActionTypes.LOAD_RATED,
      movies: mapMovies,
   };
}
