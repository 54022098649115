import genreActionTypes from "../actions/genreActionTypes";
import api from '../stores/data.json';

export function loadGenre(genre) {
  //español
  const mapMovies = [];
  for (let i=0; i < api.movies.length; i++){
    if (api.movies[i].genre.toString().toLowerCase().includes(genre.toString().toLowerCase())){
      mapMovies.push(api.movies[i]);
    }
  }
    return {
      type: genreActionTypes.LOAD_GENRE,
      movies: mapMovies,
   };
}
