import languageActionTypes from "../actions/languageActionTypes";
import api from '../stores/data.json';

export function loadLanguage(language) {
  //español
  const mapMovies = [];

  for (let i=0; i < api.movies.length; i++){
    if (language === 'esp'){
      if (api.movies[i].esp === './flags/spainflag.png'){
        mapMovies.push(api.movies[i]);
      }
    }
    if (language === 'lat'){
      if (api.movies[i].lat === './flags/mexicoflag.png'){
        mapMovies.push(api.movies[i]);
      }
    }
    if (language === 'vose'){
      if (api.movies[i].vose === './flags/vose.png'){
        mapMovies.push(api.movies[i]);
      }
    }
  }
  return {
    type: languageActionTypes.LOAD_LANGUAGE,
    movies: mapMovies,
  };
}
