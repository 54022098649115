import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRated } from '../redux/actions/ratedActionCreator.js';
import { loadGenre } from '../redux/actions/genreActionCreator.js';
import { loadLanguage } from '../redux/actions/languageActionCreator.js';
import { closedMenu, openedMenu } from '../redux/actions/menuStateActionCreator.js';

const Navbar = () => {
  const dispatch = useDispatch();
  const [barState, setBarState] = useState('none'); 
  const selectedMovieState = useSelector((state) => state.menuStateReducer);

  useEffect(() => {
    document.getElementById("input-movie-container").style.display = (barState);  
  })
  const toggleNav = () => {
    dispatch(openedMenu());
    let navEle = document.getElementById('mainMenu');
    document.getElementById('web-name').style.display = 'flex';
    document.getElementById('logo').style.display = 'flex';
    document.getElementById('mag-glass').style.display = 'flex';
    document.getElementById('hamburger').classList.toggle("change");
    document.getElementById('genre-container').style.display = 'none';
    
    navEle.style.display = 'flex';
    document.body.style.overflow = 'hidden';  //block scroll when menu's opened only web view
    document.body.style.position = 'fixed';
    if(!navEle) return;
    if (!navEle.classList.contains("open")){
      navEle.setAttribute("class","open");
      if(selectedMovieState === 'opened'){
        document.getElementById('movie-selected-section').style.width = '100vw';
        document.getElementById('navbar').style.width = '100vw';
      }
      if (window.innerWidth < 600){
        document.getElementById('web-name').style.display = 'none';
        document.getElementById('mag-glass').style.display = 'none';
        document.getElementById('input-movie-container').style.display = 'none';
      }
      return;
    }else{
      document.body.style.position = 'relative';
      document.body.style.overflow = 'auto';
      document.getElementById('input-movie-container').style.display = barState;
      document.getElementById('mag-glass').style.display = 'flex';
      navEle.style.display = 'flex';
      dispatch(closedMenu());
    }
      navEle.removeAttribute("class");
  }
  const searchMovie = () => {   //to solve 2 clicks 
    if (barState === 'flex'){
      setBarState('none');
    }else{
      setBarState('flex');
    }
  }
  const closeMenu = () => {
    dispatch(closedMenu());
    document.body.style.position = 'relative';
    const menu = document.getElementById('mainMenu');
    document.getElementById('web-name').style.display = 'flex';
    document.getElementById('logo').style.display = 'flex';
    document.getElementById('mag-glass').style.display = 'flex';
    document.getElementById('genre-container').style.display = 'none';
    menu.removeAttribute("class");
    document.getElementById('hamburger').classList.toggle("change");
    document.body.style.overflow = 'scroll';

    if (window.innerWidth < 600){
      menu.style.display = 'none';
      document.getElementById('input-movie-container').style.display = 'none';
    }
  }
  const handleGenre = () => {
    document.getElementById('genre-container').style.display = 'flex';
  }
  const handleGenreOption = (genre) => {
    closeMenu();
    document.getElementById('genre-container').style.display = 'none';
    dispatch(loadGenre(genre)); 
  }
  const handleRateOption = () => {
    closeMenu();
    dispatch(loadRated()); 
    document.getElementById('genre-container').style.display = 'none';
  }
  const handleLanguage = (language) => {
    closeMenu();
    dispatch(loadLanguage(language)); 
  }
  return (
      <>
        <nav id="mainMenu">
          <ul>
            <li><Link to='/' className='nowplaying' onClick={closeMenu}>Novedades</Link></li>
            <li><Link to='/mostRated' className='rated' onClick={handleRateOption}>Mejor valoradas</Link></li>
            <li>
              <div className='languages-_container'>
                <Link to='/moviesByLanguage' className='spanish' onClick={() => handleLanguage('esp')}>
                </Link>
                <Link to='/moviesByLanguage' className='latin' onClick={() => handleLanguage('lat')}>
                </Link>
                <Link to='/moviesByLanguage' className='vose' onClick={() => handleLanguage('vose')}>
                </Link>
              </div>
            </li>
            <li className='genre--button' onClick={handleGenre}>Género</li>
            <div className='genre--section'>
              <div className='genre--container' id='genre-container'>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('drama')}>Drama</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('thriller')}>Thriller</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('romance')}>Romance</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('acción')}>Acción</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('terror')}>Terror</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('ciencia-ficción')}>Ciencia-ficción</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('musical')}>Musical</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('animación')}>Animación</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('comedia')}>Comedia</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('bélico')}>Bélico</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('infantil')}>Infantil</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('documental')}>Documental</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('western')}>Western</Link>
                <Link to='moviesByGenre' className='genre__options' onClick={() => handleGenreOption('aventuras')}>Fantástico / Aventuras</Link>
              </div>
            </div>
            <div className='dmca--navbar--container' onClick={closeMenu}>
              <div><Link to='/dmca' className='dmca--navbar'>DMCA</Link></div>
            </div>
          </ul>
        </nav>
        <div className='navbar' id='navbar'> 
            <ul className='navbar__options'>
              <li>
                  <div className="hamburger" id='hamburger' onClick={toggleNav}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                  </div>
                </li>
                <span className='search' id='mag-glass' onClick={searchMovie}>&#128269;</span>
                <Link to='/' className='header--link' >
                  <div className='web--name' id='web-name'>
                      <h1 className='title'>
                          PELIADVISOR
                      </h1>
                      <div id='logo' className='logo home'></div>
                  </div>
              </Link>
            </ul>
        </div>
      </>
  );
};

export default Navbar;