import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef  } from "react";
import { loadMovie } from "../redux/actions/selectedMovieActionCreator";
import { Link } from "react-router-dom";
import { loadMovies } from "../redux/actions/localMoviesActionCreator";
import { loadRelation } from "../redux/actions/relationActionCreator";

const InputMovie = () => {
  const [movieTitle, setMovieTitle] = useState("");
  const [show, setShow] = useState(false);
  const movies = useSelector((state) => state.localMoviesReducer); 
  const [mapMovies, setMapMovies] = useState([]);
  const inputElement = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    //ios prevent input scroll down when keyboard appears    
    inputElement.current.focus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.getElementById('input-field').focus = true;
    };
    dispatch(loadMovies());
    const movieSelected = localStorage.getItem('movieSelected');
    const movie = JSON.parse(movieSelected);    
    const readyDom_kj = (fn) => {
      if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") fn();
      else document.addEventListener('DOMContentLoaded', fn);
  
    }
    readyDom_kj(() => {  
      const domains = ['mega.nz', 'megapaste.xyz', '1fichier.com', 'goodstream.uno'];
      //const landing = 'https://adclicker.io/url/';  //para utilizar adclicker
      //const userId = '65db5d855bbd3c27d997a6a8';
      var aList = document.querySelectorAll('a');
      aList.forEach((e) => {
        domains.forEach((d) => {
          if (e.href.indexOf(d) > -1) {
            //let urlApi = "api=" + userId + "&url=" + encodeURIComponent(e.href);    //para utilizar adclicker
            //e.href = landing + '#' + btoa(btoa(btoa(urlApi)));
            e.href =  `https://uii.io/st?api=4f264d43b72f838f314d892d2a3687d0dc970655&url=${movie.download}`;
          }
        });
      });
    });
    /*readyDom_kj(() => {    //for adclicker ads
      const domains = ['mega.nz', 'megapaste.xyz', '1fichier.com', 'goodstream.uno'];
      const landing = 'https://adclicker.io/url/';
      const userId = '65db613a5bbd3c27d9981087';
      var aList = document.querySelectorAll('a');
      aList.forEach((e) => {
        domains.forEach((d) => {
          if (e.href.indexOf(d) > -1) {
            let urlApi = "api=" + userId + "&url=" + encodeURIComponent(e.href);
            e.href = landing + '#' + btoa(btoa(btoa(urlApi)));
          }
        });
      });
    });*/
  })
  const handleModalClose = (e) => {
    setShow(false);
    mapMovies.length = 0;  //empty array
  };
  const handleEnterPressed = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  function handleTitle(event) {
    setMovieTitle(event.target.value);
  }
  const removeAccents = (str) => {
    const noAccent = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return noAccent;
  }
  const handleClick = () => {   
    for (let i=0; i < movies.length; i++){
      const noAccentTitle = removeAccents(movies[i].title);
      const noAccentTitleIntro = removeAccents(movieTitle);
      if (noAccentTitle.toLowerCase().includes(noAccentTitleIntro.toLowerCase())){
        mapMovies.push(movies[i]);
      }
    }
    setShow(true);
  }
  const handleMovieSelected = (movieClicked) => {
    closeMenu();
    if (document.location.href === 'https://peliadvisor.com/#/selectedMovie'){
        document.location.reload(true);  //refresh
    }else{
      document.location.href = 'https://peliadvisor.com/#/selectedMovie';
    }
    /*let uagent = navigator.userAgent.toLowerCase();   // to Safari and possible refresh
    if(/safari/.test(uagent) && !/chrome/.test(uagent)) {
        window.location.href = 'https://peliadvisor.com/#/selectedMovie';
    }*/
    dispatch(loadMovie(movieClicked));
    dispatch(loadRelation(movieClicked.relation));
    localStorage.setItem('movieSelected', JSON.stringify(movieClicked)); //localStorage
    mapMovies.length = 0;  //empty array
    //adclicker ads
    /*const readyDom_kj = (fn) => {
      if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") fn();
      else document.addEventListener('DOMContentLoaded', fn);

    }
    readyDom_kj(() => {    //adclicker ads
      const domains = ['mega.nz', 'megapaste.xyz', '1fichier.com', 'goodstream.uno'];
      const landing = 'https://adclicker.io/url/';
      const userId = '65db5d855bbd3c27d997a6a8';
      var aList = document.querySelectorAll('a');
      aList.forEach((e) => {
        domains.forEach((d) => {
          if (e.href.indexOf(d) > -1) {
            let urlApi = "api=" + userId + "&url=" + encodeURIComponent(e.href);
            e.href = landing + '#' + btoa(btoa(btoa(urlApi)));
          }
        });
      });
    });*/
  }
  const closeMenu = () => {
    const menu = document.getElementById('mainMenu');
    if (menu.classList.contains("open")) {
      document.getElementById('hamburger').classList.toggle("change"); 
    }
    document.body.style.position = 'relative';
    document.getElementById('web-name').style.display = 'flex';
    document.getElementById('logo').style.display = 'flex';
    document.getElementById('mag-glass').style.display = 'flex';
    document.getElementById('genre-container').style.display = 'none';
    menu.removeAttribute("class");
    document.body.style.overflow = 'scroll';

    if (window.innerWidth < 600){
      menu.style.display = 'none';
      document.getElementById('input-movie-container').style.display = 'none';
    }
  }
  return (
    <>
      <div className="input--movie--container" id='input-movie-container'>
        <input
          type="search"
          className="input__field"
          onChange={handleTitle}
          ref={inputElement}//iOS prevent input scroll down
          value={movieTitle}
          placeholder=" Nombre de la película"
          onKeyPress={handleEnterPressed}
          id='input-field'
          autoFocus
        />
        <button className="search__button" onClick={handleClick}>
          Buscar
        </button>
      </div>

      <div hidden={!show}>
        <div className="modal--background" onClick={handleModalClose}>
          <div>
            <div className="modal--card">
              {mapMovies?.length > 0 ? 
                mapMovies.map((movie) => (
                  <>
                    <div className="modal__result" key={movie.id}>
                      <Link
                          to='/selectedMovie'
                          className="modal__button"
                          onClick={() => handleMovieSelected(movie)}
                          type="button"
                          key={movie.id}
                        >
                        <div
                        className="movie__picture"
                        style={{
                          backgroundImage: `url(${movie.picture})`,
                        }}
                        title={movie.title}
                        >
                        <div className="vote-_container">
                          <span className='vote__average'>&#11088; {movie.vote_average}</span>
                        </div>
                        </div>
                        <div className="languages-_container">
                            <span className='sub' id='sub'
                            style={{
                            backgroundImage: `url(${movie.vose})`,}}>
                            </span>
                            <span className='esp' id='esp'
                            style={{
                            backgroundImage: `url(${movie.esp})`,
                            }}>
                            </span>
                            <span className='lat' id='lat'
                            style={{
                            backgroundImage: `url(${movie.lat})`,
                            }}
                            >
                          </span>
                        </div>
                      </Link>
                    </div>
                  </>
                )) : (
                  <div className='no--results--container'>
                        <div className='no--results'>Sin resultados.  &#128533;</div>
                    </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default InputMovie;
