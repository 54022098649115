import relationActionTypes from "../actions/relationActionTypes";

function relationReducer(movies = [], action) {
  switch (action.type) {
    case relationActionTypes.LOAD_RELATION:
      return action.movies;
    default:
      return movies;
  }
}

export default relationReducer;
