import './App.scss';
import { Provider } from 'react-redux';
import store from './redux/stores';
import { HashRouter, Routes, Route } from "react-router-dom";
import DMCA from './components/DMCA';
import Navbar from './components/Navbar';
import ShowMovies from './components/ShowMovies';
import SelectedMovie from './components/SelectedMovie';
import InputMovie from './components/InputMovie';
import MoviesByGenre from './components/MoviesByGenre';
import MostRated from './components/MostRated';
import Contact from './components/Contact';
import MoviesByLanguage from './components/MoviesByLanguage';

function App() {
  return (
    <>
    <Provider store={store}>
        <HashRouter>
        <Navbar />
        <InputMovie />
          <Routes>
            <Route path="/" exact element={<ShowMovies />} />
            <Route path="/showMovies" exact element={<ShowMovies />} />
            <Route path="/dmca" exact element={<DMCA />} />
            <Route path="/selectedMovie" exact element={<SelectedMovie />} />
            <Route path='/moviesByGenre' exact element={<MoviesByGenre />} />
            <Route path='/moviesByLanguage' exact element={<MoviesByLanguage />} />
            <Route path='/mostRated' exact element={<MostRated />} />
            <Route path='/contact' exact element={<Contact />} />
          </Routes>
        </HashRouter>
      </Provider>
    </>
  );
}

export default App;
