import { applyMiddleware } from "redux";
import { legacy_createStore as createStore } from 'redux';
import { thunk } from "redux-thunk";
import rootReducer from "../reducers";
import { composeWithDevTools } from "@redux-devtools/extension";

const composeEnhancers = (middlewares) => {
  //console.log(process.env.NODE_ENV)
  return process.env.NODE_ENV !== "production"
    ? composeWithDevTools(middlewares)
    : middlewares;
};

const store = createStore(  
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);



export default store;