import localMoviesActionTypes from "../actions/localMoviesActionTypes";

function localMoviesReducer(movies = [], action) {
  switch (action.type) {
    case localMoviesActionTypes.LOAD_MOVIES:
      return action.movies;

    default:
      return movies;
  }
}

export default localMoviesReducer;