import relationActionTypes from "../actions/relationActionTypes";
import api from '../stores/data.json';

export function loadRelation(relation) {
  //español
  const mapMovies = [];
  const selectedIndex = [];
  const selectedMovies = [];
  for (let i=0; i < api.movies.length; i++){
    if (api.movies[i].relation.toString().toLowerCase().includes(relation.toString().toLowerCase())){
      mapMovies.push(api.movies[i]);
    }
  }
  for (let i=0; i < mapMovies.length; i++){
    let number = Math.round(Math.random() * (mapMovies.length - 1));
    if (!selectedIndex.includes(number) && selectedIndex.length < 4){
        selectedIndex.push(number);
        selectedMovies.push(mapMovies[number]);
    }
  }
  localStorage.setItem('moviesRelated', JSON.stringify(selectedMovies)); //localStorage

  return {
    type: relationActionTypes.LOAD_RELATION,
    movies: selectedMovies,
  };
}
