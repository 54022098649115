import ratedActionTypes from "../actions/ratedActionTypes";

function ratedReducer(movies = [], action) {
  switch (action.type) {
    case ratedActionTypes.LOAD_RATED:
      return action.movies;
    default:
      return movies;
  }
}

export default ratedReducer;
