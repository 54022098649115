import menuStateActionTypes from "./menuStateActionTypes";

export function openedMenu() {
  return {
    type: menuStateActionTypes.OPENED_MENU,
    state: 'opened'
  };
}

export function closedMenu() {
    return {
      type: menuStateActionTypes.CLOSED_MENU,
      state: 'closed'
    };
}
