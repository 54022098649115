import genreActionTypes from "../actions/genreActionTypes";

function genreReducer(movies = [], action) {
  switch (action.type) {
    case genreActionTypes.LOAD_GENRE:
      return action.movies;
    default:
      return movies;
  }
}

export default genreReducer;
