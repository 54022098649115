import localMoviesActionTypes from "../actions/localMoviesActionTypes";
import api from '../stores/data.json';

export function loadMovies() {
  //español
    return {
      type: localMoviesActionTypes.LOAD_MOVIES,
      movies: api.movies,
   };
}
