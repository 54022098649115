import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { loadMovies } from "../redux/actions/localMoviesActionCreator";
import { loadMovie } from "../redux/actions/selectedMovieActionCreator";
import { loadGenre } from "../redux/actions/genreActionCreator";
import { loadRelation } from "../redux/actions/relationActionCreator";
import { closedMenu, openedMenu } from '../redux/actions/menuStateActionCreator.js';
import { Link } from "react-router-dom";

const ShowMovies = () => {
  const movies = useSelector((state) => state.localMoviesReducer);
  useEffect(() => {   
      dispatch(loadMovies());
    }, []);
  const dispatch = useDispatch();
  const [endList, setEndList] = useState(40);
  const [startList, setStartList] = useState(0);
  const handleMovieSelected = (movie) => {
    localStorage.setItem('movieSelected', JSON.stringify(movie)); //localStorage
    closeMenu();
    dispatch(closedMenu());
    dispatch(loadMovie(movie));
    document.getElementById('top--button').classList.remove('show');
    dispatch(loadGenre(movie.genre)); 
    dispatch(loadRelation(movie.relation));
    dispatch(openedMenu());
  }
  window.onscroll = function () {   //load more movies
    let totalPageHeight = document.body.scrollHeight;
    let scrollPoint = window.scrollY + window.innerHeight;
    if (scrollPoint >= totalPageHeight){
        loadMoreMovies();
    }
  }
  const toTop = () => {
      if (window.scrollY >= 100) {
        document.getElementById('top--button').classList.add('show');
      } else {
        document.getElementById('top--button').classList.remove('show');
      }
  }
  window.addEventListener("scroll", toTop);
  window.addEventListener("resize", toTop);
  const loadMoreMovies = () =>{
    setEndList(endList+10);
  }
  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }  
  const closeMenu = () => {
    const menu = document.getElementById('mainMenu');
    if (menu.classList.contains("open")) {
      document.getElementById('hamburger').classList.toggle("change"); 
    }
    document.body.style.position = 'relative';
    document.getElementById('web-name').style.display = 'flex';
    document.getElementById('logo').style.display = 'flex';
    document.getElementById('mag-glass').style.display = 'flex';
    document.getElementById('genre-container').style.display = 'none';
    menu.removeAttribute("class");
    document.body.style.overflow = 'scroll';
    if (window.innerWidth < 600){
      menu.style.display = 'none';
      document.getElementById('input-movie-container').style.display = 'none';
    }
  }
  return (
    <>
    <div className='button--top' id='top--button' onClick={topFunction} title='Go to top'>
        <div className='primary text'>&#x21EA;</div>
        <div className='secondary text'>Top</div>
    </div>  
    <div className="title--component--container" id="title-component-container" onClick={closeMenu}>
        <div className="title">Novedades</div>
    </div>
    <div className="map--container" id="map-container" onClick={closeMenu}>
      <div className="map">
        {movies.sort(function(a, b){
                    return b.id - a.id;
                  })
          .slice(startList, endList).map((movie) => (  
          <div className="card--container square-in" id='popular-movie-card' key={movie.id}>
                <div className="card square-in" id='popular-movie-card' >
                  <Link
                      to={'/selectedMovie'}
                      className='linked-_card '
                      onClick={() => handleMovieSelected(movie)}
                      type="button"
                    >
                      <div
                        className="movie__picture"
                        style={{
                          backgroundImage: `url(${movie.picture})`,
                        }}
                        title={movie.title}
                      >
                        <div className="vote-_container">
                          <span className='vote__average'>&#11088; {movie.vote_average}</span>
                        </div>
                        <div className="languages-_container">
                          <span className='sub' id='sub'
                          style={{
                          backgroundImage: `url(${movie.vose})`,}}>
                          </span>
                          <span className='esp' id='esp'
                          style={{
                          backgroundImage: `url(${movie.esp})`,
                          }}>
                          </span>
                          <span className='lat' id='lat'
                          style={{
                          backgroundImage: `url(${movie.lat})`,
                          }}
                          >
                          </span>
                        </div>
                      </div>
                  </Link>
                </div>
          </div> 
        ))}
      </div>
    </div>
    </>
  );
};

export default ShowMovies;