import React from 'react';
import { useState } from 'react';
import { send } from '@emailjs/browser';
import Footer from './Footer';

const DMCA = () => {
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
        email: '',
    });
    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_4yqhpt9',
            'template_ar6elm7',
            toSend,
            'HfwbK562y5GnAC6v-'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            })
            .catch((err) => {
                console.log('FAILED...', err);
            });   
            document.getElementById('name').value = '';
            document.getElementById('email').value = '';
            document.getElementById('message').value = '';
            alert('Request sent!');
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    const toTop = () => {
        if (window.scrollY >= 100) {
          document.getElementById('top--button').classList.add('show');
        } else {
          document.getElementById('top--button').classList.remove('show');
        }
    }
    window.addEventListener("scroll", toTop);
    window.addEventListener("resize", toTop);
    const topFunction = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } 
    const closeMenu = () => {
        const menu = document.getElementById('mainMenu');
        if (menu.classList.contains("open")) {
          document.getElementById('hamburger').classList.toggle("change"); 
        }
        document.body.style.position = 'relative';
        document.getElementById('web-name').style.display = 'flex';
        document.getElementById('logo').style.display = 'flex';
        document.getElementById('mag-glass').style.display = 'flex';
        document.getElementById('genre-container').style.display = 'none';
        menu.removeAttribute("class");
        document.body.style.overflow = 'scroll';
    
        if (window.innerWidth < 600){
          menu.style.display = 'none';
          document.getElementById('input-movie-container').style.display = 'none';
        }
    }
    return (
        <>
        <div className='button--top' id='top--button' onClick={topFunction} title='Go to top'>
            <div className='primary text'>&#x21EA;</div>
            <div className='secondary text'>Top</div>
        </div> 
        <div className='title--component--container' onClick={closeMenu}>
            <span className='title'>Disclaimer</span>
        </div>
        <div className='dmca' onClick={closeMenu}>
            <section className='dmca-_section'>
                The information provided on Peliadvisor.com ("the Site") is for general informational purposes only.
                <br /> 
                All information on the Site is provided in good faith, however we make no representation of warranty of any kind, express or implied, regarding the accuracy, adequacy, 
                validity, reliability, availability or completeness of any information on the Site.
                <br />
                No multimedia files are hosted on the Site. 
                <br />
                We have no relationship with the contents hosted on remote servers.
                <br />
                The Site works as a search engine and the files available for download are hosted on third party servers, therefore we have no control over those files.
                <br /><br />
                However you can file your request filling the form you can find below if you are detecting a copyright violation.
                <br />
                Send us your request information and infringed content URLs to be moderated, blocked or deleted following the DMCA (Section 512 (f)). 
                <br />
                Please, let us know your company email address in the form.
                <br />
                Requests asking to remove links from servers such as "mega", "powvideo", "1fichier" or others will be discarded, because they are third party services with which 
                we have no relationship or communication in any way, therefore we can't act on those files.
            </section>
        </div>
        <div className='dmca--feedback--form' id="contact">
            <form className='form--container' 
            onSubmit={onSubmit}>
                <div className='sub--title' id='sub-title'>
                    <h1 id='contact-title'>Submit your request</h1>
                </div>
                <div className='fields__container'>
                    <input
                        className='name__field'
                        type='text'
                        name='from_name'
                        placeholder='Name'
                        value={toSend.from_name}
                        onChange={handleChange}
                        id='name'
                    />
                    <input
                        className='email__field'
                        type='email'
                        name='reply_to'
                        placeholder='Email'
                        value={toSend.reply_to}
                        onChange={handleChange}
                        id='email' required
                    />
                    <textarea
                        className='text__field'
                        name='message'
                        placeholder='Request'
                        value={toSend.message}
                        onChange={handleChange}
                        id='message'
                    />
                </div>
                <button 
                    className='submit__button' 
                    type='submit'
                    id='submit-button'
                >
                    Submit
                </button>
            </form>
            <div className='contact--details'>    
                <div className='social__media'>
                    <a className="insta" href="https://www.instagram.com" target="_blank" alt='instagram logo' title="instagram logo"></a>
                    <a className='face' href='https://www.facebook.com' target="_blank" alt='facebook logo' title="facebook logo"></a>
                    <a className='twit' href='https://www.twitter.com' target="_blank" alt='twitter logo' title="twitter logo"></a>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
};

export default DMCA;