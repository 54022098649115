import React from 'react';

const Footer = () => {
    return (
        <footer data-testid='footerText' id='footer'>
            <a  className='footer__link'>
                Peliadvisor
                <sup>
                    &#169;
                </sup>
            </a>
            <span className='credit'>Based on TMDB data</span>
        </footer>
    );
};

export default Footer;