import languageActionTypes from "../actions/languageActionTypes";

function languageReducer(movies = [], action) {
  switch (action.type) {
    case languageActionTypes.LOAD_LANGUAGE:
      return action.movies;
    default:
      return movies;
  }
}

export default languageReducer;
