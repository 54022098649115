import React from 'react';
import { useState } from 'react';
import { send } from '@emailjs/browser';

const Contact = () => {
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
        email: ''
    });
    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_4yqhpt9',
            'template_ar6elm7',
            toSend,
            'HfwbK562y5GnAC6v-'
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });   
        document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('message').value = '';
        alert('Mensaje enviado.');      
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    }
    return (
        <div className='feedback--form' id="contact">
            <form className='form--container' 
            onSubmit={onSubmit}>
                <div className='sub--title' id='sub-title'>
                    <h1 id='contact-title'>Envíanos tu comentario</h1>
                </div>
                <div className='fields__container'>
                    <input
                        className='name__field'
                        type='text'
                        name='from_name'
                        placeholder='Nombre'
                        value={toSend.from_name}
                        onChange={handleChange}
                        id='name' required
                    />
                    <input
                        className='email__field'
                        type='email'
                        name='reply_to'
                        placeholder='Email'
                        value={toSend.reply_to}
                        onChange={handleChange}
                        id='email' required
                    />
                    <textarea
                        className='text__field'
                        name='message'
                        placeholder='Comentario'
                        value={toSend.message}
                        onChange={handleChange}
                        id='message' required
                    />
                </div>
                <button 
                    className='submit__button' 
                    type='submit'
                    id='submit-button'
                >
                    Enviar
                </button>
            </form>
            <div className='contact--details'>
                <div className='social__media'>
                    <a className="insta" href="https://www.instagram.com" target="_blank" alt='instagram logo' title="instagram logo"></a>
                    <a className='face' href='https://www.facebook.com' target="_blank" alt='facebook logo' title="facebook logo"></a>
                    <a className='twit' href='https://www.twitter.com' target="_blank" alt='twitter logo' title="twitter logo"></a>
                </div>
            </div>
                
        </div>
    );
};

export default Contact;