import { combineReducers } from 'redux';
import selectedMovieReducer from './selectedMovieReducer';
import localMoviesReducer from './localMoviesReducer';
import genreReducer from './genreReducer';
import ratedReducer from './ratedReducer';
import languageReducer from './languageReducer';
import relationReducer from './relationReducer';
import menuStateReducer from './menuStateReducer';

const rootReducer = combineReducers ({
    selectedMovie: selectedMovieReducer,
    localMoviesReducer: localMoviesReducer,
    genreReducer: genreReducer,
    ratedReducer: ratedReducer,
    languageReducer: languageReducer,
    relationReducer: relationReducer,
    menuStateReducer: menuStateReducer,
   })

export default rootReducer;